import axios from 'axios';
import pick from 'lodash/pick';
import { ServiceAccount } from 'redux/serviceAccount/serviceAccount.reducer';
import { SupportedLanguage } from 'translation';

const CURRENCY = 'EUR';
const TRANSACTION_SOURCE_EWALLET_KIOSK = '8';
const TRANSACTION_TYPE = '1';
const TRANSACTION_PAYMENT_ORIGIN = '5';

export enum AccountPaymentType {
  PREPAYMENT = 'PREPAYMENT',
  POSTPAYMENT = 'POSTPAYMENT',
  CASH = 'CASH',
}

export interface StudentAttributes {
  studentId: number;
  customerId: number;
  accountPaymentType: AccountPaymentType;
}

export interface Customer {
  firstName: string;
  lastName: string;
  badgeNumber: string;
  customerId: number;
  customerBalance: number;
  class: string;
  accountPaymentType: AccountPaymentType | null;
}

export interface Brand {
  brandId: string;
  name: string;
  locale: string;
  currency: string;
  contactPhone: string;
  contactEmail: string;
  siren: string;
  taxNumber: string;
  address: Address;
  industryClassificationCode: string;
}
export interface Address {
  route: string;
  postalCode: string;
  streetNumber: string;
  administrativeAreaLevel1: string;
  administrativeAreaLevel2: string;
}

export interface AuthResponse {
  access_token: string;
  status: number;
  code: string;
  message: string;
  data: {
    accessToken: string;
    tokenType: string;
    user: {
      userId: number;
      firstName: string;
      lastName: string;
      email: string;
      role: string;
      brandId: number;
    };
  };
}

export async function createAuthToken(
  mail: string,
  password: string,
  locale: SupportedLanguage,
): Promise<{
  accessToken: string;
  tokenType: string;
  serviceAccount: ServiceAccount;
}> {
  const { data } = await axios.post<AuthResponse>(
    `${process.env.REACT_APP_INNOVORDER_API}/oauth/login`,
    {
      username: mail,
      password,
      grant_type: 'password',
    },
    {
      headers: {
        'io-locale': locale,
      },
    },
  );

  if (data.data.user.role === 'ewalletKiosk') {
    return {
      accessToken: data.data.accessToken,
      tokenType: data.data.tokenType,
      serviceAccount: data.data.user,
    };
  }
  throw new Error("Vos identifiant n'ont pas le bon role");
}

export async function getCustomerFromBadgeNumber(
  badgeNumber: number | string,
  auth_token: string,
): Promise<Customer> {
  const { data } = await axios.get<{
    data: {
      customerId: number;
      firstName: string;
      lastName: string;
      email: string;
      classroom: string;
      badgeNumber: string;
      balance: string;
      accountPaymentType: AccountPaymentType;
    };
  }>(`${process.env.REACT_APP_INNOVORDER_API}/customers/information`, {
    params: {
      badge_number: badgeNumber,
    },
    headers: {
      authorization: `Bearer ${auth_token}`,
    },
  });

  return {
    ...pick(data.data, ['customerId', 'firstName', 'lastName', 'email']),
    class: data.data.classroom,
    badgeNumber: data.data.badgeNumber,
    customerBalance: Math.round(parseFloat(data.data.balance) * 100),
    accountPaymentType: data.data.accountPaymentType,
  };
}

export interface TransactionDraft {
  transactionDraftId: number;
  customerId: number;
  idempotencyKey: string;
  amount: number;
  currency: string;
}

export async function createTransactionDraft({
  customerId,
  idempotencyKey,
  amount,
  auth_token,
}: {
  customerId: number;
  idempotencyKey: string;
  amount: number;
  auth_token: string;
}): Promise<TransactionDraft> {
  const response = await axios.post<{ data: TransactionDraft }>(
    `${process.env.REACT_APP_INNOVORDER_API}/transaction_drafts`,
    {
      customerId,
      idempotencyKey,
      amount,
      currency: CURRENCY,
      source: TRANSACTION_SOURCE_EWALLET_KIOSK,
      type: TRANSACTION_TYPE,
    },
    {
      headers: {
        authorization: `Bearer ${auth_token}`,
      },
    },
  );

  return response.data.data;
}

export async function validateTransactionDraft(
  transactionDraftId: number,
  ticket: string,
  auth_token: string,
): Promise<unknown> {
  const response = await axios.post<TransactionDraft>(
    `${process.env.REACT_APP_INNOVORDER_API}/transaction_drafts/${transactionDraftId}/validate`,
    { ticket, paymentMethod: TRANSACTION_PAYMENT_ORIGIN },
    {
      headers: {
        authorization: `Bearer ${auth_token}`,
      },
    },
  );

  return response.data;
}

export async function cancelTransactionDraft(
  transactionDraftId: number,
  auth_token: string,
): Promise<unknown> {
  const response = await axios.post<TransactionDraft>(
    `${process.env.REACT_APP_INNOVORDER_API}/transaction_drafts/${transactionDraftId}/cancel`,
    undefined,
    {
      headers: {
        authorization: `Bearer ${auth_token}`,
      },
    },
  );

  return response.data;
}

export async function getBrandInfos(
  brandId: number,
  auth_token: string,
): Promise<Brand> {
  const legalResponse = await axios.get<{
    data: {
      siren: string;
      taxNumber: string;
      address: Address;
      industryClassificationCode: string;
    };
  }>(`${process.env.REACT_APP_INNOVORDER_API}/brands/${brandId}/legal`, {
    headers: {
      authorization: `Bearer ${auth_token}`,
    },
  });

  const brandResponse = await axios.get<{
    data: {
      brandId: string;
      name: string;
      locale: string;
      currency: string;
      contactPhone: string;
      contactEmail: string;
    };
  }>(`${process.env.REACT_APP_INNOVORDER_API}/brands/${brandId}`, {
    headers: {
      authorization: `Bearer ${auth_token}`,
    },
  });

  return {
    ...brandResponse.data.data,
    ...legalResponse.data.data,
  };
}

export async function rebootKiosk(
  serialNumber: string,
  auth_token: string,
): Promise<unknown> {
  const response = await axios.post<TransactionDraft>(
    `${process.env.REACT_APP_INNOVORDER_API}/eloview/${serialNumber}/reboot`,
    undefined,
    {
      headers: {
        authorization: `Bearer ${auth_token}`,
      },
    },
  );

  return response.data;
}
