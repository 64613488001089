import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { getToken } from 'redux/app/app.selectors';
import { getKioskSerialNumber } from 'redux/hardware/hardware.selectors';
import { getBrandId } from 'redux/serviceAccount/serviceAccount.selectors';
import PaymentInProcess from './PaymentInProcess.page';

const mapStateToProps = (
  state: RootState,
): {
  token: string | undefined;
  serialNumber: string;
  brandId: number | undefined;
} => {
  return {
    token: getToken(state),
    serialNumber: getKioskSerialNumber(state),
    brandId: getBrandId(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInProcess);
