/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { WebBridge, WebBridgeServiceFactory } from 'services/webBridge';
import {
  BRIDGE_MESSAGE,
  MessageResponsePayload,
} from 'services/webBridge/webBridge.types';
import simpleLogService from 'services/SimpleLog';
import { v4 as uuid } from 'uuid';
import * as Sentry from '@sentry/react';
import { NeptingConfiguration, NeptingTransaction } from './nepting.types';
import { withTimeout } from './utils';

const SERVICE_NAME = 'NeptingService';
export const DELAY_TPE_RESPONSE = 10 * 1000;

type Message<T> = {
  resolve: (value: T) => void;
  reject: (value: unknown) => void;
};

type MessageMap<T> = {
  [key: string]: Message<T>;
};

export type NeptingSuccessfullTransactionResponse = {
  merchantTicket: null;
  merchantLabel: string;
  maskedPan: string;
  merchantTransactionId: string;
  isTestCard: boolean;
  isSignatureRequired: boolean;
  handWrittenTicket: string;
  customerTicket: string;
  initialDebitTransactionId: null;
  cardEndOfValidity: string;
  isLocalMode: boolean;
  cardToken: string;
  dateTime: string;
  authorizationNumber: string;
  authorizationCode: string;
  localTransactionCount: number;
  amount: number;
};

type NeptingResponses = NeptingSuccessfullTransactionResponse | unknown;

class NeptingService {
  private webBridge: WebBridge;

  private neptingConfiguration?: NeptingConfiguration;

  private pendingMessages: MessageMap<NeptingResponses> = {};

  public transactionId?: string;

  public initialized: boolean;

  private listenerInitialised = false;

  constructor(webbridge: WebBridge) {
    simpleLogService.info(
      `[NeptingService][constructor] construct new NeptingService`,
    );
    this.webBridge = webbridge;
    this.initialized = false;
    this.initialized = false;
  }

  /**
   * Promisify event emission using a payload enveloppe containing a messageId.
   *
   * @param {string} message - The event "name".
   * @param {*} payload - The payload of the event.
   * @returns {Promise<*>} - The promise holding the response.
   */
  emitMessage = <T>(
    message: BRIDGE_MESSAGE,
    payload?: NeptingConfiguration | NeptingTransaction,
    optionalMessageId?: string,
  ): Promise<T> =>
    new Promise((resolve, reject) => {
      const messageId: string = optionalMessageId ?? uuid();

      simpleLogService.info(
        `[NeptingService][emitMessage] Emitting message - Type: ${message}, MessageId: ${messageId}`,
      );

      if (this.webBridge && this.webBridge.ready) {
        this.pendingMessages[messageId] = { resolve, reject } as Message<
          NeptingResponses
        >;
        simpleLogService.info(
          `[NeptingService][emitMessage] Bridge ready - Pending messages count: ${
            Object.keys(this.pendingMessages).length
          }`,
        );
        this.webBridge.emitMessage(
          message,
          payload ? { ...payload, messageId } : { messageId },
        );
      } else {
        simpleLogService.info(
          `[NeptingService][emitMessage] Bridge not ready or unavailable`,
        );
        reject(Error('NEPTING_WEB_BRIDGE_UNAVAILABLE'));
      }
    });

  /**
   * Init webbridge listener to Nepting message responses.
   */
  initWebBridgeListener = (): boolean => {
    simpleLogService.info(
      `[NetpingService][initWebBridgeListener] init new WebBridgeListener`,
    );
    // eslint-disable-next-line consistent-return
    const listener = (response: MessageResponsePayload) => {
      if (!response.messageId) {
        return;
      }
      simpleLogService.info(
        `[NetpingService][pendingMessages] Received message response for ${response.messageId}`,
        response,
      );

      if (this.pendingMessages[response.messageId]) {
        const { resolve, reject } = this.pendingMessages[response.messageId];
        delete this.pendingMessages[response.messageId];

        if (response.error) {
          simpleLogService.info(
            `[NetpingService][pendingMessages][response] error will reject promise for ${response.messageId}`,
            response,
          );
          // eslint-disable-next-line consistent-return
          return reject({
            ...(typeof response.payload === 'object' ? response.payload : {}),
            transactionId: response.messageId,
          });
        }

        // eslint-disable-next-line consistent-return
        return resolve(response.payload);
      }

      // TODO better error handling
      console.error(SERVICE_NAME, 'No pending messages for', response);
      simpleLogService.info(
        `[NetpingService][pendingMessages] No pending messages for`,
        response,
      );
    };

    if (this.webBridge.ready) {
      this.webBridge.on(
        BRIDGE_MESSAGE.NEPTING_PINPAD_RESPONSE,
        'NEPTING_SERVICE',
        listener,
      );
    }

    return true;
  };

  /**
   *
   * @param restaurantId
   * @param channelId
   * @returns {Promise<init>}
   */
  // eslint-disable-next-line consistent-return
  init = async (merchantId: string, shouldForceInitialize: boolean) => {
    if (!this.listenerInitialised) {
      this.listenerInitialised = this.initWebBridgeListener();
    }

    return this.initConnection(merchantId, shouldForceInitialize).catch(err => {
      console.error(SERVICE_NAME, 'Could not init connection', err);
      this.disconnect();
      throw Error('Could not init connection');
    });
  };

  /**
   *
   * @returns {Promise<unknown>}
   */
  async initConnection(merchantId: string, forceInitialize: boolean) {
    this.initialized = false;

    simpleLogService.info(
      '[NetpingService][initConnection] emitMessage init message',
    );

    const login = await withTimeout(
      this.emitMessage(BRIDGE_MESSAGE.NEPTING_PINPAD_INITIALIZE, {
        merchantId,
        forceInitialize,
      }),
      DELAY_TPE_RESPONSE,
    );

    this.initialized = true;
    this.neptingConfiguration = {
      merchantId,
    };

    return login;
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  getTerminalInfo = () => {
    simpleLogService.info(
      '[NetpingService][getTerminalInfo] emit get info message',
    );
    return withTimeout(
      this.emitMessage(BRIDGE_MESSAGE.NEPTING_PINPAD_GET_TERMINAL_INFORMATION),
      DELAY_TPE_RESPONSE,
    );
  };

  /**
   *
   * @param cents
   * @param currency
   * @param paymentMethod
   * @returns {Promise<unknown>}
   */
  startTransaction = ({
    cents,
    neptingMerchantId,
  }: {
    cents: number;
    neptingMerchantId: string | null;
  }): Promise<NeptingSuccessfullTransactionResponse> => {
    simpleLogService.info(
      `[NeptingService][startTransaction] Starting new transaction - Amount: ${cents} cents, Current transactionId: ${this.transactionId}`,
    );

    if (this.transactionId) {
      simpleLogService.info(
        `[NeptingService][startTransaction] Transaction blocked - Already in progress`,
      );
      throw Error('NEPTING_PENDING_TRANSACTION');
    }

    const transactionId = uuid();
    simpleLogService.info(
      `[NeptingService][startTransaction] New transaction initialized with ID: ${transactionId}`,
    );

    // No need to timeout, Nepting SDK is doing timeout on his own.
    // this.startPendingTransactionTimeout(transactionId);

    const timeout = setTimeout(() => {
      simpleLogService.info(
        `[NeptingService][startTransaction] Transaction : ${transactionId} is still pending after 2 minutes with neptingMerchantId : ${neptingMerchantId}`,
      );

      Sentry.captureException('Transaction still pending after 2 minutes', {
        contexts: {
          transaction: {
            transactionId,
            neptingMerchantId,
          },
        },
      });
    }, 1000 * 120);

    return this.emitMessage<NeptingSuccessfullTransactionResponse>(
      BRIDGE_MESSAGE.NEPTING_PINPAD_START_TRANSACTION,
      {
        cents,
        currency: 'EUR',
        paymentMethod: 'DEBIT',
        merchantTransactionId: transactionId,
      },
      transactionId,
    ).finally(() => {
      simpleLogService.info(
        `[NeptingService][startTransaction] Transaction ${transactionId} cleanup - Setting flags to false`,
      );
      this.transactionId = undefined;
      clearTimeout(timeout);
    });
  };

  //   /**
  //    *
  //    * @returns {Promise<unknown>}
  //    */
  abortTransaction = (): Promise<void> => {
    simpleLogService.info(
      '[NetpingService][abortTransaction] send aborting transaction',
    );
    return this.emitMessage(BRIDGE_MESSAGE.NEPTING_PINPAD_ABORT_TRANSACTION);
  };

  /**
   *
   * @returns {boolean}
   */
  isInitialized() {
    return !!this.initialized;
  }

  disconnect() {
    simpleLogService.info(
      `[NeptingService][disconnect] Disconnecting service - Pending messages: ${
        Object.keys(this.pendingMessages).length
      }, Current transactionId: ${this.transactionId}`,
    );
    this.initialized = false;
    this.pendingMessages = {};
    this.transactionId = undefined;
    this.webBridge.off(
      BRIDGE_MESSAGE.NEPTING_PINPAD_RESPONSE,
      'NEPTING_SERVICE',
    );
  }
}

export const NeptingServiceFactory = (function() {
  let instance: NeptingService;
  return {
    getInstance() {
      if (instance == null) {
        instance = new NeptingService(WebBridgeServiceFactory.getInstance());
      }
      return instance;
    },
  };
})();

const singleton = new NeptingService(WebBridgeServiceFactory.getInstance());

export default singleton;
