import {
  put,
  takeLatest,
  take,
  delay,
  call,
  race,
  select,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { Action } from 'redux';
import { push } from 'connected-react-router';
import { getCustomerFromBadgeNumber } from 'services/innovorder';
import simpleLogService from 'services/SimpleLog';
import { startPayment, selectAmount } from 'redux/reload/reload.actions';
import { getToken } from 'redux/app/app.selectors';
import { TimeoutError, WebBridgeServiceFactory } from 'services/webBridge';
import { isReady } from 'redux/hardware/hardware.selectors';
import { setIsListeningForBadge } from 'redux/hardware/hardware.actions';
import {
  customerUpdated,
  disconnectCustomer,
  restartInctivityWatcher,
  customerFailedToAuthenticate,
  badgeRead,
  displayWaitingForBadge,
  waitingForBadge,
} from './customer.actions';

export const INACTIVITY_TIMEOUT = 1000 * 25;
export const BADGE_PAGE_TIMEOUT = 1000 * 5;

function* handleBadgeRead({ payload }: ReturnType<typeof badgeRead>): Saga {
  yield put(setIsListeningForBadge(false));
  yield put<Action>(displayWaitingForBadge());
  const authToken = yield select(getToken);
  const { badgeNumber } = payload;

  try {
    const customer: AsyncReturnType<typeof getCustomerFromBadgeNumber> = yield call(
      getCustomerFromBadgeNumber,
      badgeNumber,
      authToken,
    );

    yield put<Action>(customerUpdated(customer));
    simpleLogService.info(
      `[CustomerSaga][handleBadgeRead] new customer logged in : ${customer.customerId}`,
    );
    yield put<Action>(push('/reload'));
  } catch (e) {
    yield put<Action>(customerFailedToAuthenticate());
  }
}

function* waitingForBadgeHandler(): Saga {
  const ready = yield select(isReady);

  if (!ready) {
    return;
  }

  const webBridgeService = WebBridgeServiceFactory.getInstance();

  try {
    yield put(setIsListeningForBadge(true));
    const badgeNumber = yield call(webBridgeService.startReadContactless);
    yield put(badgeRead(badgeNumber));
  } catch (e) {
    if (e instanceof TimeoutError) {
      console.log(
        '[CustomerSaga][waitingForBadgeHandler] Timeout error occurred',
      );
      yield put<Action>(disconnectCustomer());
      yield put<Action>(push('/'));
    } else {
      yield put<Action>(customerFailedToAuthenticate());
    }
  }
}

function* inactivityWatcher(): Saga {
  const { action, reload, login } = yield race({
    action: take(getType(selectAmount)),
    login: take(getType(customerUpdated)),
    reload: delay(INACTIVITY_TIMEOUT),
    payment: take(getType(startPayment)),
  });

  if (action || login) {
    yield put<Action>(restartInctivityWatcher());
  }
  if (reload) {
    yield put<Action>(disconnectCustomer());
  }
}

function* handleDisplayWaitingForBadge(): Saga {
  const ready = yield select(isReady);

  if (!ready) {
    return;
  }

  yield put<Action>(push('/badge'));

  const { timeout } = yield race({
    badge: take(getType(badgeRead)),
    action: take(getType(selectAmount)),
    timeout: delay(BADGE_PAGE_TIMEOUT),
  });

  if (timeout) {
    yield put<Action>(disconnectCustomer());
    yield put<Action>(push('/'));
  }
}

export function* watchCustomerActions(): Saga {
  // TODO : Il faut trouver un event qui permet de démarrer waitingForBadgeHandler mais qui soit pas hardwareUpdate (car appelé trop de fois) mais qui attende que le matos soit preet pour démarrer
  yield takeLatest(getType(disconnectCustomer), waitingForBadgeHandler);
  yield takeLatest(getType(waitingForBadge), waitingForBadgeHandler);
  yield takeLatest(
    getType(customerFailedToAuthenticate),
    waitingForBadgeHandler,
  );

  yield takeLatest(getType(badgeRead), handleBadgeRead);
  yield takeLatest(getType(customerUpdated), inactivityWatcher);
  yield takeLatest(getType(customerFailedToAuthenticate), inactivityWatcher);
  yield takeLatest(getType(restartInctivityWatcher), inactivityWatcher);
  yield takeLatest(
    getType(displayWaitingForBadge),
    handleDisplayWaitingForBadge,
  );
}
