import React from 'react';
import { FormattedMessage } from 'react-intl';
import MainPage from 'components/MainPage';
import LangagePicker from 'components/LangagePicker';

const HomePage: React.FunctionComponent<{
  isReady: boolean;
  displayWaitingForBadge: () => void;
}> = ({ isReady, displayWaitingForBadge }) => {
  return (
    <MainPage canBadge={isReady} onPress={displayWaitingForBadge}>
      <LangagePicker />
      {isReady && <FormattedMessage id="home.welcome" />}
      {!isReady && <FormattedMessage id="home.unavailable" />}
    </MainPage>
  );
};

export default HomePage;
