import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { getNeptingErrorMessage } from 'redux/nepting/nepting.selectors';
import { startNeptingPayment } from 'redux/nepting/nepting.actions';
import PaymentError from './PaymentError.page';

const mapStateToProps = (
  state: RootState,
): { errorMessage: string | null } => ({
  errorMessage: getNeptingErrorMessage(state),
});

const mapDispatchToProps = {
  startNeptingPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentError);
