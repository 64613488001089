import { createAction } from 'typesafe-actions';
import { Customer } from 'services/innovorder';

export const badgeRead = createAction('BADGE_READ', (badgeNumber: string) => ({
  badgeNumber,
}))();

export const displayWaitingForBadge = createAction(
  'DISPLAY_WAITING_FOR_BADGE',
)();
export const waitingForBadge = createAction('WAITING_FOR_BADGE')();
export const restartInctivityWatcher = createAction(
  'RESTART_INACTIVITY_WATCHER',
)();
export const customerUpdated = createAction(
  'CUSTOMER_UPDATED',
  (customer: Customer) => customer,
)();
export const customerFailedToAuthenticate = createAction(
  'CUSTOMER_FAILED_TO_AUTHENTICATE',
)();
export const disconnectCustomer = createAction('DISCONNECT_CUSTOMER')();
