import { createAction } from 'typesafe-actions';
import { TerminalInfo } from 'services/Nepting/nepting.types';
import { PrinterInfos } from 'services/Printer/printer.types';

export const hardwareUpdate = createAction(
  'HARDWARE_UPDATE',
  (
    isWebBridgeConnected: boolean,
    printer: PrinterInfos | null,
    neptingTerminalInfo?: TerminalInfo,
  ) => ({
    isWebBridgeConnected,
    neptingTerminalInfo,
    printer,
  }),
)();

export const hardwareUpdateFailed = createAction('HARDWARE_UPDATE_FAILED')();
export const addHardwareUpdateFailCount = createAction(
  'ADD_HARDAWARE_FAIL_COUNT',
)();
export const requestHardwareUpdate = createAction('REQUEST_HARDWARE_UPDATE')();

export const setShouldReboot = createAction(
  'hardware/SET_SHOULD_REBOOT',
  (shouldReboot: boolean) => ({
    shouldReboot,
  }),
)();

export const setSerialNumber = createAction(
  'hardware/SET_SERIAL_NUMBER',
  (kioskSerialNumber: string) => ({
    kioskSerialNumber,
  }),
)();

export const setIsListeningForBadge = createAction(
  'hardware/SET_IS_LISTENING_FOR_BADGE',
  (isListening: boolean) => ({
    isListening,
  }),
)();
