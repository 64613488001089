import React, { useEffect, useState } from 'react';
import { rebootKiosk } from 'services/innovorder';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';
import simpleLogService from 'services/SimpleLog';
import styled from 'styled';

import CenteredIcon from 'components/CenteredIcon';
import Header from 'components/Header';
import cb from './cb.svg';

const RebootButton = styled.button<{ disabled: boolean }>`
  margin-top: 10px;
  padding: 10px;
  height: 12%;
  width: 40%;
  font-weight: 900;
  font-family: ${({ theme }): string => theme.font.family}, sans-serif;
  background-color: ${({ theme }): string => theme.color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }): string => theme.color.textOnPrimary};
  font-size: ${({ theme }): string => theme.font.size.small};
  text-transform: uppercase;
  outline: none;
  border: none;
  opacity: ${({ disabled }): number => (disabled ? 0.5 : 1)};
`;

const Background = styled.div`
  text-align: center;
  background-color: ${({ theme }): string => theme.color.background};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: ${({ theme }): string => theme.color.text};
`;

const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }): string => theme.color.text};
`;

const TransparentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 600px 0 0;
  max-width: 700px;
  margin-bottom: 20px;
`;

const PageTitle = styled.h1`
  font-weight: 900;
  font-size: ${({ theme }): string => theme.font.size.title};
  margin: 0;
`;

const RebootHelpText = styled(FormattedMessage)`
  font-size: ${({ theme }): string => theme.font.size.small};
`;

interface Props {
  token: string | undefined;
  serialNumber: string;
  brandId: number | undefined;
}

const PaymentInProcess: React.FunctionComponent<Props> = ({
  token,
  serialNumber,
  brandId,
}) => {
  const [displayRebootButton, setDisplayRebootButton] = useState(false);
  const [disableRebootButton, setDisableRebootButton] = useState(false);

  const handleReboot = (): void => {
    setDisableRebootButton(true);
    setTimeout(() => {
      setDisableRebootButton(false);
    }, 60000);

    simpleLogService.info(
      `[PaymentInProcess][handleReboot] Rebooting device : ${serialNumber} `,
    );

    Sentry.captureException(
      '[PaymentInProcess][handleReboot] Rebooting device',
      {
        contexts: {
          info: {
            serialNumber,
            brandId,
          },
        },
      },
    );

    token && rebootKiosk(serialNumber, token);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayRebootButton(true);
    }, 60000);

    return (): void => clearTimeout(timeout);
  }, []);

  return (
    <Background>
      <Header />
      <Container>
        <TransparentContainer>
          <CenteredIcon src={cb} alt={'Insert your CB'} />
          <PageTitle>
            <FormattedMessage id="PaymentInProcess.title" />
          </PageTitle>
        </TransparentContainer>
        {displayRebootButton && token && serialNumber && (
          <>
            <RebootHelpText id="PaymentInProcess.alertReboot" />
            <br />
            <RebootButton
              onClick={handleReboot}
              disabled={disableRebootButton}
              data-testid="reboot-button"
            >
              <FormattedMessage id="PaymentInProcess.reboot" />
            </RebootButton>
          </>
        )}
      </Container>
    </Background>
  );
};

export default PaymentInProcess;
