import { fork, delay, call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { WebBridgeServiceFactory } from 'services/webBridge/index';
import neptingService from 'services/Nepting/nepting.service';
import { getNeptingMerchantId } from 'redux/configuration/configuration.selectors';
import printerService from 'services/Printer/printer.service';
import * as Sentry from '@sentry/react';
import simpleLogService from 'services/SimpleLog';
import { getNeptingTransactionStatus } from 'redux/nepting/nepting.selectors';
import { NeptingPaymentStatus } from 'redux/nepting/nepting.reducer';
import { getToken } from 'redux/app/app.selectors';
import { rebootKiosk } from 'services/innovorder';
import { InfoPayload } from 'services/SimpleLog/index.types';
import {
  disconnectCustomer,
  waitingForBadge,
} from 'redux/customer/customer.actions';
import { hasAmount } from 'redux/reload/reload.selectors';
import {
  addHardwareUpdateFailCount,
  hardwareUpdate,
  hardwareUpdateFailed,
  requestHardwareUpdate,
  setSerialNumber,
  setShouldReboot,
} from './hardware.actions';
import {
  getFailedTry,
  getIsListeningForBadge,
  getKioskSerialNumber,
  getShouldReboot,
  isReady,
} from './hardware.selectors';

const HARDWARE_UPDATE_POLLING_INTERVAL = 1000 * 60 * 5;
const HARDWARE_UPDATE_ERROR_POLLING_INTERVAL = 1000 * 15;
const RETRY_BEFORE_REBOOT = 5;

function* updateHarwarehandler(): Saga {
  while (true) {
    try {
      const bridge = WebBridgeServiceFactory.getInstance();

      // eslint-disable-next-line no-unused-vars
      yield call(bridge.searchConnection);

      const neptingMerchantId: ReturnType<typeof getNeptingMerchantId> = yield select(
        getNeptingMerchantId,
      );

      const failedTry: ReturnType<typeof getFailedTry> = yield select(
        getFailedTry,
      );

      const isWebBridgeConnected = !!bridge.ready;
      let success = false;

      if (!isWebBridgeConnected) {
        throw new Error('Not connected');
      }

      if (!neptingMerchantId) {
        yield put(hardwareUpdate(isWebBridgeConnected, null));
      }

      const neptingTransactionStatus: ReturnType<typeof getNeptingTransactionStatus> = yield select(
        getNeptingTransactionStatus,
      );

      const serialNumber: ReturnType<typeof getKioskSerialNumber> = yield select(
        getKioskSerialNumber,
      );

      const shouldReboot: ReturnType<typeof getShouldReboot> = yield select(
        getShouldReboot,
      );

      const token: ReturnType<typeof getToken> = yield select(getToken);

      if (shouldReboot && token && serialNumber && bridge.ready) {
        simpleLogService.info(
          `[hardware.sagas] should reboot detected, calling reboot API..`,
        );

        try {
          yield call(rebootKiosk, serialNumber, token);
        } catch (error) {
          yield put(hardwareUpdateFailed());

          simpleLogService.info(`[hardware.sagas][catch] Kiosk reboot failed`);

          Sentry.captureException('Kiosk reboot failed', {
            contexts: {
              hardware: {
                serialNumber,
                isWebBridgeConnected,
              },
            },
          });

          return;
        }
      }

      if (neptingMerchantId && !neptingService.initialized) {
        try {
          const shouldForceInitialize = failedTry > 3;

          yield call(
            neptingService.init,
            neptingMerchantId,
            shouldForceInitialize,
          );

          const neptingTerminalInfo = yield call(
            neptingService.getTerminalInfo,
          );

          const printer = yield call(printerService.searchUSBPrinter);
          success = true;

          yield put(
            hardwareUpdate(isWebBridgeConnected, printer, neptingTerminalInfo),
          );
        } catch (error) {
          if (failedTry === RETRY_BEFORE_REBOOT) {
            simpleLogService.info(
              `[hardware.sagas][updateHarwarehandler] TPE is not connected after 10 retries`,
            );
            Sentry.captureException('TPE is not connected');
            yield put(setShouldReboot(true));
          }

          yield put(addHardwareUpdateFailCount());
          yield put(hardwareUpdate(isWebBridgeConnected, null));
        }
      } else if (
        neptingMerchantId &&
        neptingTransactionStatus !== NeptingPaymentStatus.PENDING
      ) {
        try {
          const neptingTerminalInfo = yield call(
            neptingService.getTerminalInfo,
          );

          simpleLogService.info(
            `[hardware.sagas][neptingTerminalInfo] TPE is connected`,
          );

          const printer = yield call(printerService.searchUSBPrinter);
          success = true;

          yield put(
            hardwareUpdate(isWebBridgeConnected, printer, neptingTerminalInfo),
          );
        } catch (error) {
          simpleLogService.info(`[hardware.sagas][catch] disconnect nepting`);
          neptingService.disconnect();
          throw new Error('Not connected');
        }
      }

      if (!serialNumber && bridge.ready) {
        try {
          const newSerialNumber: string = yield call(
            bridge.getSerialKioskNumber,
          );

          if (newSerialNumber) {
            simpleLogService.info(
              `[hardware.sagas][updateHarwarehandler] Serial number received: ${newSerialNumber}`,
            );
            yield put(setSerialNumber(newSerialNumber));
          }
        } catch (error) {
          simpleLogService.info(
            '[hardware.sagas][updateHarwarehandler] Failed to get serial number',
            error as InfoPayload,
          );
        }
      }

      yield delay(
        success
          ? HARDWARE_UPDATE_POLLING_INTERVAL
          : HARDWARE_UPDATE_ERROR_POLLING_INTERVAL,
      );
    } catch (e) {
      yield put(hardwareUpdateFailed());
      yield delay(HARDWARE_UPDATE_ERROR_POLLING_INTERVAL);
    }
  }
}

function* startBadgeReadingAfterHardwareUpdate(): Saga {
  const isHardwareReady: ReturnType<typeof isReady> = yield select(isReady);
  const hasRelodAmount: ReturnType<typeof isReady> = yield select(hasAmount);
  const isListening: ReturnType<typeof isReady> = yield select(
    getIsListeningForBadge,
  );

  if (isHardwareReady && !isListening && !hasRelodAmount) {
    yield put(waitingForBadge());
  }
}

export function* watchHardwareActions(): Saga {
  yield fork(updateHarwarehandler);
  yield takeLatest(getType(requestHardwareUpdate), updateHarwarehandler);
  yield takeLatest(getType(disconnectCustomer), updateHarwarehandler);
  yield takeLatest(
    getType(hardwareUpdate),
    startBadgeReadingAfterHardwareUpdate,
  );
}
