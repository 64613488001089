import { createReducer, ActionType } from 'typesafe-actions';
import { TerminalInfo } from 'services/Nepting/nepting.types';
import { PrinterInfos } from 'services/Printer/printer.types';
import * as hardwareActions from './hardware.actions';

export type HardwareActions = ActionType<typeof hardwareActions>;

const defaultState = {
  isWebBridgeConnected: false,
  printer: null,
  failedTry: 0,
  shouldReboot: false,
  kioskSerialNumber: '',
  isListeningForBadge: false,
};

export type HardwareState = {
  isWebBridgeConnected: boolean;
  printer: PrinterInfos | null;
  failedTry: number;
  neptingTerminalInfo?: TerminalInfo;
  shouldReboot: boolean;
  kioskSerialNumber: string;
  isListeningForBadge: boolean;
};

const hardwareReducer = createReducer<HardwareState, HardwareActions>(
  defaultState,
)
  .handleAction(
    hardwareActions.hardwareUpdate,
    (
      state,
      { payload: { isWebBridgeConnected, neptingTerminalInfo, printer } },
    ) => ({
      ...state,
      isWebBridgeConnected,
      neptingTerminalInfo,
      printer,
      failedTry: neptingTerminalInfo ? 0 : state.failedTry,
    }),
  )
  .handleAction(hardwareActions.addHardwareUpdateFailCount, state => ({
    ...state,
    failedTry: state.failedTry + 1,
  }))
  .handleAction(hardwareActions.setShouldReboot, (state, { payload }) => ({
    ...state,
    shouldReboot: payload.shouldReboot,
  }))
  .handleAction(hardwareActions.setSerialNumber, (state, { payload }) => ({
    ...state,
    kioskSerialNumber: payload.kioskSerialNumber,
  }))
  .handleAction(hardwareActions.hardwareUpdateFailed, state => ({
    ...defaultState,
    kioskSerialNumber: state.kioskSerialNumber,
  }))
  .handleAction(
    hardwareActions.setIsListeningForBadge,
    (state, { payload }) => ({
      ...state,
      isListeningForBadge: payload.isListening,
    }),
  );

export default hardwareReducer;
